body, html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.fullscreenDiv {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.center {
  position: absolute;
  width: 300px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -150px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  background-color: #ffffff;
  padding: 0;
}

.centerRes {
  position: absolute;
  width: 80vw;
  height: 80vh;
  top: 10vh;
  left: 10vw;
  text-align: center;
  
  background-color: #ffffff;
}

.centerResP {
  font-weight: bold;
  color: #000000;
  font-size: 24px;
  line-height: 28px;
}

.centerResP2 {
  font-weight: bold;
  color: #777777;
  font-size: 20px;
  line-height: 24px;
}

.centerResP3 {
  font-weight: 400;
  color: #444444;
  font-size: 16px;
  line-height: 20px;
}

.checkMark {
  width: 100px;
  height: auto;
}